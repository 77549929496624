import './Publications.css';
import data from "../content/Publications.json";
import { Helmet } from 'react-helmet-async';

function highlightName(text: string): JSX.Element {
    const highlightedText = text.replace(/Charles,? A\. Emogor/g, '<strong>Charles A. Emogor</strong>');
    return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
}
interface PublicationProps {
    title: string;
    journal: string;
    type: string;
    doi: string;
    contributors: string;
    year: string;
}

function PublicationItem(props: PublicationProps): JSX.Element{ 
    return (
        <div className='Publication-item-full'>  
            <div className='Publication-item-container'>
                <div className='Publication-header'>
                    <h2>{props.title}</h2>
                    <span className={`Publication-type ${props.type === "Preprint" ? 'preprint' : 'journal'}`}>{props.type}</span>
                </div>
                <p><strong>Journal: </strong>{props.journal}</p>
                <p><strong>DOI: </strong><a href={`https://doi.org/${props.doi}`} target="_blank" rel="noopener noreferrer">{props.doi}</a></p>
                <p><strong>Authors: </strong>{highlightName(props.contributors)}</p>
            </div>
        </div>
    );
}

function groupByYear(publications: any[]): { [key: string]: any[] } {
    return publications.reduce((acc: { [key: string]: any[] }, publication) => {
        const year = publication.year;
        if (!acc[year]) {
            acc[year] = [];
        }
        acc[year].push(publication);
        return acc;
    }, {});
}

export function Publications(): JSX.Element {
    // Group publications by year
    const publicationsByYear = groupByYear(data.publications);

    return (
        <div className="Publications-Container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Charles Emogor | Publications</title>
                <meta name="description" content='Conservationist and tropical ecologist'></meta>
                <link rel="canonical" href="/publication" />
            </Helmet>

            {/* ORCID Link */}
            <div className="Orcid-Link">
                <a href="https://orcid.org/0000-0002-9589-2931" target="_blank" rel="noopener noreferrer">
                    Visit my ORCID profile
                </a>
            </div>

            {/* Render publications grouped by year */}
            {Object.keys(publicationsByYear).sort((a, b) => Number(b) - Number(a)).map((year) => (
                <div key={year} className="Publications-Year-Group">
                    <h2 className="Publication-Year">{year}</h2>
                    <div className="Publications-Container-Items">
                        {publicationsByYear[year].map((item, index) => (
                            <PublicationItem
                                key={index}
                                title={item.title}
                                journal={item.journal}
                                type={item.type}
                                doi={item.doi}
                                contributors={item.contributors}
                                year={item.year}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
